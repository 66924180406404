.authentication-wrapper {
    display: flex;
    flex-basis: 100%;
    min-height: 100vh;
    width: 100%;
}

.authentication-wrapper .authentication-inner {
    width: 100%;
}

.authentication-wrapper.authentication-basic {
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.authentication-wrapper.authentication-cover {
    align-items: flex-start;
}

.authentication-wrapper.authentication-cover .authentication-inner {
    height: 100vh;
}

.authentication-wrapper.authentication-basic .authentication-inner {
    max-width: 25rem;
    position: relative;
}

.authentication-wrapper.authentication-basic .authentication-inner:before {
    width: 21rem;
    height: 21rem;
    content: " ";
    position: fixed;
    top: -100px;
    background: url("../../../img/pages/auth-top-left.png");
    background-size: contain;
}

html:not([dir=rtl]) .authentication-wrapper.authentication-basic .authentication-inner:before {
    left: -100px;
}

[dir=rtl] .authentication-wrapper.authentication-basic .authentication-inner:before {
    right: -100px;
    transform: rotate(90deg);
}

.authentication-wrapper.authentication-basic .authentication-inner:after {
    width: 7rem;
    height: 14.125rem;
    content: " ";
    position: fixed;
    bottom: 0;
    background-image: url("../../../img/pages/auth-right-bottom.png");
    background-size: contain;
}

html:not([dir=rtl]) .authentication-wrapper.authentication-basic .authentication-inner:after {
    right: 0;
}

[dir=rtl] .authentication-wrapper.authentication-basic .authentication-inner:after {
    left: 0;
    transform: rotate(180deg);
}

.authentication-wrapper.authentication-basic .authentication-inner .card {
    z-index: 1;
}

.authentication-wrapper.authentication-basic .authentication-inner .card .app-brand {
    margin-bottom: 2.5rem;
}

.authentication-wrapper .auth-input-wrapper .auth-input {
    max-width: 50px;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    font-size: 150%;
}

.authentication-cover-img {
    margin-bottom: 1rem;
}

@media (max-width: 575.98px) {
    .authentication-wrapper .auth-input-wrapper .auth-input {
        font-size: 1.125rem;
    }
}

#twoStepsForm .fv-plugins-bootstrap5-row-invalid .form-control {
    border-color: #ff5b5c;
}

.light-style .authentication-wrapper .authentication-bg {
    background-color: #fff;
}

.dark-style .authentication-wrapper .authentication-bg {
    background-color: #283144;
}

@media (min-height: 737px) {
    .authentication-cover-img {
        margin-bottom: 3rem;
    }
}
