@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,400;1,500;1,600;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Noto+Serif+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');*/

body {
    margin: 0;
    font-family: 'Prompt', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    background: #f5f5f5 !important;
    overflow-x: hidden;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    font-family: 'Prompt', sans-serif !important;
}

code {
    font-family: 'Prompt', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.bx-sm {
    font-size: 1.55rem !important;
}

.app-brand .layout-menu-toggle svg {
    color: #28b5b5;
}

.layout-navbar,
.layout-menu-fixed .layout-page {
    transition: all .3s;
}

@media (max-width: 1199px) {
    .layout-menu {
        transition: all .3s !important;
    }

    .layout-menu-collapsed .layout-menu {
        transition: all .3s;
        transform: translate3d(0, 0, 0);
    }

    .layout-menu-collapsed body {
        overflow-y: hidden;
    }
}

.search-input-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
}

.search-input-wrapper input {
    flex: 1 1 auto;
    flex-grow: 1;
}

.layout-navbar .search-input-wrapper .search-toggler {
    top: 50% !important;
    transform: translateY(-50%);
}

.nav-item .btn {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.nav-item .btn:hover {
    box-shadow: none !important;
}

.nav-item .dropdown-toggle::after {
    display: none;
}

.nav-item svg {
    color: #677788;
}

.layout-navbar .navbar-dropdown .dropdown-menu {
    min-width: auto !important;
    padding-bottom: 0;
}

@media (max-width: 767px) {
    .layout-navbar .navbar-dropdown .dropdown-menu {
        min-width: 200px !important;
    }
}

.layout-navbar .dropdown-notifications .dropdown-menu {
    min-width: 22rem !important;
    max-width: 30rem !important;
}

.dropdown-notifications {
    white-space: normal;
}

.dropdown-notifications p {
    word-break: break-word;
    word-wrap: break-word;
    white-space: normal;
}

@media (max-width: 991px) {
    .boxwyswyg .tools {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}


body.react-confirm-alert-body-element {
    overflow: hidden;
}

.react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(0, 0, 0, 0.9) !important;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    opacity: 0;
    -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
    animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
    font-family: 'Prompt', sans-serif !important;
    width: 400px;
    padding: 30px;
    text-align: left;
    background: #28b5b5 !important;
    border-radius: 10px;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
    color: #fff !important;
}

.react-confirm-alert-svg {
    position: absolute;
    top: 0;
    left: 0;
}

.react-confirm-alert-body > h1 {
    margin-top: 0;
}

.react-confirm-alert-body > h3 {
    margin: 0;
    font-size: 16px;
}

.react-confirm-alert-body h1 {
    font-size: 20px;
    color: #fff !important;

}

.react-confirm-alert-button-group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.react-confirm-alert-button-group > button {
    outline: none;
    background: #fff !important;
    border: none;
    display: inline-block;
    padding: 6px 18px;
    color: #000 !important;
    margin-right: 10px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes react-confirm-alert-fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.faq-header {
    position: relative;
    background-color: rgba(40, 181, 181, 0.08);
    min-height: 270px !important;
    border-radius: 0.25rem;
    overflow: hidden;
}

.faq-header:before {
    width: 28rem;
    height: 28rem;
    content: " ";
    position: absolute;
    top: -151px;
    background: url("common/assets/img/pages/auth-top-left.png");
    background-size: contain;
    background-repeat: no-repeat;
}

html:not([dir=rtl]) .faq-header:before {
    left: -135px;
}

[dir=rtl] .faq-header:before {
    right: -135px;
    transform: rotate(90deg);
}

.faq-header:after {
    width: 10rem;
    height: 20rem;
    content: " ";
    position: absolute;
    bottom: -100px;
    background-image: url("common/assets/img/pages/auth-right-bottom.png");
    background-size: contain;
    background-repeat: no-repeat;
}

html:not([dir=rtl]) .faq-header:after {
    right: -1.8rem;
    transform: rotate(45deg);
}

[dir=rtl] .faq-header:after {
    left: -1.8rem;
    transform: rotate(135deg);
}

.faq-header .input-wrapper {
    position: relative;
    width: 100%;
    max-width: 55%;
}

.faq-header .input-wrapper .input-group-text,
.faq-header .input-wrapper .form-control {
    border-color: transparent;
}

@media (max-width: 575.98px) {
    .faq-header .input-wrapper {
        max-width: 70%;
    }
}

.faq-nav-icon {
    font-size: 1.25rem;
}

.light-style .bg-faq-section {
    background-color: rgba(38, 60, 85, 0.06);
}

.dark-style .bg-faq-section {
    background-color: rgba(99, 123, 169, 0.08);
}

.notification-container .notification .title {
    color: #fff;
}

.table_list_avatar.overlay li:not(:first-child) {
    position: relative;
    transform: translateX(-50%);

}

.table_list_avatar.overlay li {
    margin-top: -5px;
}


.table_list_avatar.overlay li .avatar-image .img-card-placeholder {
    border: 2px solid #fff;
    padding-top: 86.5%;
}

.avatar-image.sBorder .img-card-placeholder {
    border: 4px solid #00b8b7;
    padding-top: 93.5%;
}

.avatar-image-border .img-card-placeholder {
    border: 5px solid #fff;
    padding-top: 93.5%;
}

.avatar-image.normal .img-card-placeholder,
.avatar-image.normal {
    width: 100px;
    height: 100px;
    border-radius: 5px;
}

.avatar-image,
.avatar-image .img-card-placeholder {
    width: 48px;
}

.avatar-image.xl,
.avatar-image.xl .img-card-placeholder {
    width: 150px;
    font-size: 32px;
}

.avatar-image.lg,
.avatar-image.lg .img-card-placeholder {
    width: 100px;
    font-size: 32px;
}

.avatar-image.xs,
.avatar-image.xs .img-card-placeholder {
    width: 30px;
    font-size: 11px;
    /*font-size: 32px;*/
}

.avatar-image.sm,
.avatar-image.sm .img-card-placeholder {
    width: 36px;
    font-size: 12px;
    /*font-size: 32px;*/
}

.avatar-image.md,
.avatar-image.md .img-card-placeholder {
    width: 68px;
    font-size: 14px;
    /*font-size: 32px;*/
}

.img-card-placeholder {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 100%;
    display: block;
    position: relative;
    overflow: hidden;
}

.img-circle {
    border-radius: 50%;
    background-color: #e7ebef !important;
    color: #69809a !important;
    /*background-image: url("../public/assets/images/default.jpg");*/
}

.img-card-s1 {
    padding-top: 100%;
}

.img-card-s2 {
    padding-top: 60%;
}

.img-card-placeholder .image {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all 1s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-group.disabled,
.input-group-text.disabled {
    background-color: #e9ecee;
}

/*-webkit-background-clip,*/
input:-webkit-autofill:disabled,
input:-webkit-autofill:disabled:hover,
.form-control:disabled, .form-control[readonly] {
    background-color: #e9ecee !important;
    -webkit-background-clip: unset !important;
}

input:-webkit-autofill:disabled,
input:-webkit-autofill {
    /*background-color: transparent !important;*/
    /*-webkit-box-shadow: 0 0 0 50px white inset;*/
}


.box-upload {
    position: relative;
}

.box-upload input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.box-upload audio,
.box-upload video {
    max-width: 70%;
    position: relative;
    z-index: 999999999999;
}

.box-upload video {
    max-height: 400px;
}

.bs-stepper-header .step {
    position: relative;

}

.bs-stepper-header .line {
    position: relative;
    z-index: 88;
}

.bs-stepper-header .step {
    z-index: 91;
}

/*.bs-stepper-header :nth-child(2) .step {*/
/*    z-index: 92;*/
/*}*/

/*.bs-stepper-header :nth-child(3) .step {*/
/*    z-index: 93;*/
/*}*/

/*.bs-stepper-header :nth-child(4) .step {*/
/*    z-index: 94;*/
/*}*/

/*.bs-stepper-header :nth-child(5) .step {*/
/*    z-index: 95;*/
/*}*/

/*.bs-stepper-header :nth-child(6) .step {*/
/*    z-index: 96;*/
/*}*/

.light-style .bs-stepper .bs-stepper-header .step:not(.active) .bs-stepper-circle {
    background-color: #fff;
}

.light-style .bs-stepper .bs-stepper-header .step:not(.active) .bs-stepper-circle svg {

}

.bs-stepper .active button.step-trigger:focus svg {
    color: #fff !important;
}

.progressBarPopup.active {
    transform: none;
}

.progressBarPopup {
    color: #fff;
    transition: all 0.3s;
    transform: translateY(150%);
    position: fixed;
    bottom: 15px;
    right: 15px;
    position: fixed;
    min-width: 250px;
    padding: 15px 20px;
    background-color: #28b5b5;
    border-radius: 5px;
    box-shadow: 0 0.125rem 0.25rem rgb(147 158 170 / 40%);
}

.progressBarPopup .inner {
    display: flex;
    align-items: center;
}

.progressBarPopup .progressIcon {
    flex: 0 0 auto;
    width: 40px;
    margin-right: 10px;
}

.table_list_avatar li {
    display: inline-flex;
    float: none;
}

.paginate {
    margin: 0 30px;
}

.paginate ul,
.paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
}

@media (max-width: 767px) {
    .paginate {
        justify-content: center !important;
    }

    .paginate ul {
        margin-top: 15px !important;
        justify-content: center !important;
    }

    .paginate ul li {
        margin-bottom: 5px;
    }
}

.paginate .total-items {
    font-size: 14px;
    color: #a8a8a8;
}

.paginate ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.paginate ul li > * {
    display: block;
    padding: 8px 15px;
    background-color: #fff;
    margin: 0 3px;
    border-radius: 3px;
    font-size: 16px;
}

.paginate ul li.disabled * {
    color: #8b8b8b;
}

.paginate ul li a {
    color: #000;
}

.paginate ul li a:hover {
    text-decoration: none;

}

.paginate ul li:not(.disabled):hover > *,
.paginate ul li.selected > * {
    background-color: #28b5b5;
    color: #fff !important;
}

.react-confirm-alert-overlay {
    z-index: 9999 !important;
}


.bs-stepper .active .step-trigger:focus svg {
    color: #fff !important;
    fill: #fff !important;
}

.bs-stepper .step-trigger:focus svg {
    color: #516377;
    /*fill:  !important;*/
}

.boxwyswyg .tools .box_icon.disabled svg {
    color: #ccc;
}

.boxwyswyg .tools .box_icon.disabled:hover {
    background-color: transparent;
    cursor: not-allowed;
}

.invoice-preview-card video,
.invoice-preview-card audio,
.invoice-preview-card img {
    max-width: 100%;
}

.custom-option:hover,
.custom-option {
    border-width: 2px;
}

.has-error .form-select,
.has-error .form-control {
    border-color: #a02626;
    background-color: #fdf2f2;
}


.progress-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999988;
}

.progress-loading:after {
    content: '';
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9998;
}

.progress-inner {
    z-index: 9999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    padding: 15px;
    border-radius: 5px;
}

.has-error .invalid-feedback {
    display: block;
}


.react-datepicker__triangle {
    transform: translate(235px, 0px) !important;
}

.react-datepicker__navigation-icon {
    top: 5px !important;
}


.react-datepicker__header,
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range,
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover {
    background-color: #28b5b5 !important;
    /*color: #fff !important;*/
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #28b5b5 !important;
}

.react-datepicker__day-name,
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header,
.react-datepicker__header {
    color: #fff !important;
    border-radius: 0 !important;
}

.react-datepicker {
    border-radius: 0 !important;
    border: none !important;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14);
    -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14);
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
    display: none;
}

/*.rnc__notification{*/
/*    position: fixed;*/
/*    z-index: 9999999;*/
/*}*/

@media (max-width: 767px) {
    .w-px-700 {
        max-width: 100% !important;
        width: auto !important;
    }

    .topic_icon_size {
        width: 40px !important;
        height: 40px !important;
    }

    .app-brand-text.main {
        font-size: 20px;
    }

    .react-confirm-alert-body {
        max-width: 100% !important;
        width: 100% !important;
    }

    .react-confirm-alert-body {
        text-align: center;
        padding: 15px;
    }

    .react-confirm-alert-button-group {
        text-align: center;
        display: flex;
        justify-content: center;
    }
}


body.omise-open {
    overflow: hidden;
}
