/*
******************************************************************************/

.light-style .menu .app-brand.main {
    height: 64px;
}

.dark-style .menu .app-brand.main {
    height: 64px;
}

.app-brand-logo.main {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
    width: 30px;
    height: 30px;
}

.app-brand-logo.main svg {
    width: 1.7rem;
    height: 1.7rem;
}

.app-brand-text.main {
    font-size: 1.75rem;
    letter-spacing: -0.45px;
}

/* ! For .layout-navbar-fixed added fix padding top tpo .layout-page */
.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
    padding-top: 64px !important;
}

/* Navbar page z-index issue solution */
.content-wrapper .navbar {
    z-index: auto;
}

/*
* Content
******************************************************************************/

.main-blocks > * {
    display: block !important;
}

.main-inline-spacing > * {
    margin: 1rem 0.375rem 0 0 !important;
}

/* ? .main-vertical-spacing class is used to have vertical margins between elements. To remove margin-top from the first-child, use .main-only-element class with .main-vertical-spacing class. For example, we have used this class in forms-input-groups.html file. */
.main-vertical-spacing > * {
    margin-top: 1rem !important;
    margin-bottom: 0 !important;
}

.main-vertical-spacing.main-only-element > :first-child {
    margin-top: 0 !important;
}

.main-vertical-spacing-lg > * {
    margin-top: 1.875rem !important;
    margin-bottom: 0 !important;
}

.main-vertical-spacing-lg.main-only-element > :first-child {
    margin-top: 0 !important;
}

.main-vertical-spacing-xl > * {
    margin-top: 5rem !important;
    margin-bottom: 0 !important;
}

.main-vertical-spacing-xl.main-only-element > :first-child {
    margin-top: 0 !important;
}

.rtl-only {
    display: none !important;
    text-align: left !important;
    direction: ltr !important;
}

[dir='rtl'] .rtl-only {
    display: block !important;
}

/*
* Layout main
******************************************************************************/

.layout-main-wrapper {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 1rem;
}

.layout-main-placeholder img {
    width: 900px;
}

.layout-main-info {
    text-align: center;
    margin-top: 1rem;
}


.container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {

    max-width: 1680px !important;
}

.wyswyg_theme .ql-editor {
    min-height: 200px;
}


.textarea_hight {
    min-height: 200px !important;
}
.wyswyg_theme .ql-editor p {
    font-family: Prompt;
    font-size: 16px;
    color: #000;
}


.boxwyswyg {
    margin-bottom: 30px;
}

.boxwyswyg .tools {
    border-bottom: 1px solid #d4d8dd !important;
    padding: 5px;
    background-color: #f7f7f7;
    min-height: 60px;
    padding-left: 22px;
    padding-top: 8px;
}

.boxwyswyg .fix_min_height {

    min-height: 140px !important;

}


.boxwyswyg .boxgroup {
    padding-bottom: 10px;
}


.boxwyswyg .tools .box_icon {
    float: left;
    list-style: none;
    /* border: 1px solid #d4d8dd !important;*/
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 10px;
    margin-right: 1px;
    /*background-color: #dae0e5;*/
}


.boxwyswyg .tools .left {
    float: left !important;
}

.boxwyswyg .tools .right {
    float: right !important;
}

.boxwyswyg .tools .active {
    background-color: #dae0e5;
}

.boxwyswyg .tools .box_icon:hover {
    background-color: #dae0e5;
    cursor: pointer;
}


.boxwyswyg .tools .box_icon .icon {
    color: #212529;
    font-size: 1.5rem;
}


.boxwyswyg .box_image_upload {
    /*min-height: 120px;*/
    padding-top: 60px;
    padding-bottom: 60px;
    border: 1px solid #d4d8dd !important;
}

.boxwyswyg .box_image_upload .icon {
    color: #989c9c;
    font-size: 2.5rem;
}

.boxwyswyg .form_no_border{
    border: 0px !important;
}

.boxwyswyg .form_no_border:focus{
    border: none !important;
    box-shadow: none !important;
}
.help_padding{
    padding-left:10px ;
    padding-bottom: 10px ;
}

.tooltip_style {
    font-family: 'Prompt', sans-serif;
}

.plane_icon {
    color: #28b5b5;
}

.icon_table_size {
    font-size: 20px;
}

.manage_icon {
    margin-right: 5px;
    margin-left: 5px;
    color: #677788 !important;
}

.border_profile {
    border: solid 3px #28b5b5;
}

.border_profile2px {
    border: solid 2px #28b5b5;
}

.label_business {
    background-color: #f5a44d !important;
}

.label_etc {
    background-color: #7cd175 !important;
}

.option_button {
    float: right;
    padding: 10px;
    padding-top: 40px;
}

.option_button .btn {
    background-color: #28b5b5 !important;
    border-color: #28b5b5 !important;
}

.option_button .btn:hover {
    background-color: #7fe7ee !important;
    border-color: #7fe7ee !important;
}

.option_button .icon {
    font-size: 20px;
}

.icon_family {
    color: #467fd7;
}

.icon_friends {
    color: #fa7470;
}


.icon_business {
    color: #f5a44d;
}

.icon_etc {
    color: #7cd175;
}

.border_acive_theme {
    border-color: #28b5b5 !important;
}

.icon_alet_header {
    font-size: 20px;
}

.border_profile_white {
    border: solid 5px #ffffff;
}

.bg_theme {
    background-color: #28b5b5 !important;;
}

.receiver_profile {
    padding-top: 20px;
    padding-bottom: 20px;
}

.receiver_profile h5 {
    padding-top: 10px;
    color: #FFF;
    font-weight: 600;
    font-size: 16px;
}

.receiver_profile .nickname {
    color: #FFF;
}

.receiver_profile .tag {
    margin-top: 10px;
}

.icon_button_size {
    font-size: 20px;
}

.table_text_topic_padding_bottom {
    margin-bottom: 210px;
}

.bg-mali {
    --bs-bg-opacity: 1;
    background-color: #28b5b5 !important;
}

.bg-mali-darft {
    --bs-bg-opacity: 1;
    background-color: #bab8b8 !important;
}


.bg-mali-unopen {
    background-color: red !important;
}

.bg-label-memo {
    background-color: #28b5b5 !important;
    color: #fff !important;
}

.bg-label-password {
    background-color: #fa7470 !important;
    color: #fff !important;
}

.bg-label-friends {
    background-color: #fa7470 !important;
    color: #fff !important;
}

.bg-label-i-wish {
    background-color: #f5a44d !important;
    color: #fff !important;
}

.table_date {
    text-align: center;
}

.table_date .DateMonth {
    font-size: 12px;
}

.table_date .tags {
    margin-top: 0px;
    font-size: 9px;
}

.table_date .boxDate {
    display: inline-flex;
}


.table_date .boxDate .DateDay {
    color: #36373a;
    font-weight: 500;
    font-size: 23px;

    padding-left: 20px;
}

.table_date .boxDate .DateDayText {
    font-size: 11px;
    padding-left: 10px;
}


.table_list_avatar {
    margin-left: 0px;
    padding-inline-start: 0px !important;
    padding-top: 4px;
}

.table_list_avatar li {
    float: left;
    list-style: none;
    margin-right: -10px;
}

.user-profile-header-banner {
    position: relative;
}

.user-profile-header-banner .cover_opacity {
    position: absolute;
    background-color: #000;
    width: 100%;
    height: 350px;
    top: 0px;
    opacity: 0.4;
}


.user-profile-header-banner .boxcenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.user-profile-header-banner .boxcenter .owner {
    text-align: center;
}

.user-profile-header-banner .boxcenter .owner .owner_name {
    color: #fff;
    text-shadow: 2px 2px black;
    padding-top: 5px;
}


.user-profile-header-banner .boxcenter .owner .avatar-lg {
    margin: 0 auto !important;
}

.user-profile-header-banner .boxcenter .owner .avatar-wrapper img {
    border: solid #fff 3px;
}

.user-profile-header-banner .boxcenter h4 {
    color: #fff;
    text-shadow: 2px 2px black;
    font-size: 25px;
}

.user-profile-header-banner img {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    height: 350px;
}

.user-profile-header {
    margin-top: -2rem;
    position: relative;
}

.user-profile-header .user-profile-img {
    border: 5px solid;
    width: 120px;
}

.light-style .user-profile-header .user-profile-img {
    border-color: #fff;
}

.dark-style .user-profile-header .user-profile-img {
    border-color: #283144;
}

.dataTables_wrapper .card-header .dataTables_filter label {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

@media (max-width: 767.98px) {
    .user-profile-header-banner img {
        height: 150px;
    }

    .user-profile-header .user-profile-img {
        width: 100px;
    }

    .user-profile-header-banner .cover_opacity {
        height: 150px;
    }

    .user-profile-header-banner .boxcenter .owner {
        display: none;
    }

    .user-profile-header-banner .boxcenter h4 {
        font-size: 18px;
    }

    .widget_content_type .video {
        width: 100% !important;
    }

    .widget_content_type .audio {
        width: 100% !important;
    }

    .widget_content_type .link {
        width: 100% !important;
    }

    .widget_content_type .password {
        width: 100% !important;
    }

    .widget_content_type .password .icon {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .chart_review {
        width: 90% !important;
    }

    .chart_review_box {
        width: 90% !important;
    }

    .chart_review_bar .fw-bold {

        font-size: 12px !important;
    }
}

.widget_content {
    min-height: 200px;
    font-size: 16px;
}

.user-profile-info h4 {
    color: #323336;
    font-weight: 600;
}

.btn-mali-outline {
    border-color: #28b5b5 !important;
    color: #28b5b5 !important;
}

.btn-mali-outline:hover {
    background-color: #28b5b5 !important;
    color: #ffffff !important;
}

.border_profile_mali {
    border-color: #28b5b5 !important;
}

.widget_content_type .text_indent {
    text-indent: 4em;
}

.widget_content_type .text {
    margin-bottom: 30px !important;
}

.widget_content_type .img {
    width: 100%;
    margin-bottom: 30px !important;
}

.widget_content_type .video {
    margin: 0 auto;
    margin-bottom: 30px !important;

}

.widget_content_type .audio {
    margin: 0 auto !important;
    margin-bottom: 30px !important;
    width: 50%;
}

.widget_content_type .link {
    margin: 0 auto !important;
    margin-bottom: 30px !important;
    width: 80%;
}

.widget_content_type .password {
    margin: 0 auto !important;
    margin-bottom: 30px !important;
    width: 60%;
}

.widget_content_type .password .card-title {
    margin-bottom: 5px !important;
}

.widget_content_type .password .card-text {
    margin-bottom: 5px !important;
}

.widget_content_type .password .bg {
    background-color: #28b5b5;
}

.widget_content_type .password .icon {
    color: #FFF !important;
    font-size: 60px;
}

.widget_content_type .password small {
    font-style: italic;
}

.widget_content .center {
    text-align: center;
}

.widget_content img{
    width: 90%;
}

.chart_css {
    font-size: 40px !important;
    color: red !important;
}

.chart_review_bar .bar_text {
    background-color: #28b5b5;
    /*width: 45px !important;*/
    height: 6px
}

.chart_review_bar .bar_img {
    background-color: #71DFE7;
    width: 25px !important;
    height: 6px
}

.chart_review_bar .bar_video {
    background-color: #C2FFF9;
    width: 15px !important;
    height: 6px
}

.chart_review_bar .bar_audio {
    background-color: #FFE652;
    width: 35px !important;
    height: 6px
}

.chart_review_bar .bar_url {
    background-color: #fdac34;
    width: 25px !important;
    height: 6px
}

.chart_review_bar .bar_password {
    background-color: #5a8dee;
    width: 35px !important;
    height: 6px
}

.text_top_invoice {
    padding-top: 22px;
    padding-left: 22px;
    color: #323336;
    font-size: 16px;
    padding-bottom:20px;
}

.icon_small {
    font-size: 18px;
    margin-left: 5px;
}

.misc-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - (1.875rem * 2));
    text-align: center;
}

.box_write_receiver {
    margin-bottom: 25px;
}

.box_write_receiver .sub {
    min-height: 290px;
}

.bg_blank {
    background-color: #f2fafa;
}

.rounded-circle-avatar-icon {
    border-radius: 50%;
}

.rounded-circle-avatar-icon .icon_avatar {
    font-size: 30px;
}

.box_border {
    border: 1px solid #d4d8dd !important;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.chart_review_box {
    margin: 0 auto;
    width: 40%;
    margin-bottom: 30px;
}

.chart_review {
    margin: 0 auto;
    width: 60%;
    margin-bottom: 30px;
}

.chart_review_bar .fw-bold {
    font-weight: normal !important;
    font-size: 15px;
}

.memo_write_icon{
    font-size: 25px;
    color: #FFF !important;
}

.memo_write_icon polyline{
    stroke: #FFF !important;
}


.customer_profile_page .user-profile-img{
    width:  160px !important;
    height: 160px !important;
}

.customer_profile_page h4{
   font-size: 25px;
}

.customer_profile_page h5{
    font-size: 20px;
}
.border_bottom_solid{
    border-bottom: 1px solid #d4d8dd !important;
    padding-bottom: 20px !important;
}

.icon_white{
    color:#fff !important;
}

.icon_size_18{
   font-size: 18px!important;
}

.logo_footer{
    vertical-align: baseline !important;
    padding-left: 5px;
}

.icon_noti{

    font-size: 20px!important;
    margin-top: -20px;
    margin-right: -5px;
}
.border_bottom{
    border-bottom: 1px solid #d4d8dd !important;
}
.icon_form_notice{
    color: #28b5b5 !important;
    font-size: 16px;
}

.color_mali{
    color: #28b5b5 !important;
}

.margin_right_3px{
    margin-right: 3px!important;
}

.noti_type_icon{

}

.noti_type_icon .icon{
}

.noti_type_icon .memo{

}
.noti_type_icon .memo .icon{

}

.icon_noti_topic{
    font-size: 12px;
}

.notice .icon{
    color: #fdac41;
}

.memo .icon{
    color: #5a8dee;
}

.security .icon{
    color: #ff5b5c !important;

}

.receiver .icon{
    color: #39da8a !important;
}

.noti_box h6{
    margin-bottom: 10px !important;
}

.notitype_logo{
    width: 54px!important;
    height: 54px!important;
}

.notitype_logo .icon  {
    width: 1.7rem;
    height: 1.7rem;
}

.boxwyswyg .widget_message,.widget_image,.widget_audio,.widget_video,.widget_link,.widget_password{
    display: none;
}

.boxwyswyg .active{
    display: block !important;
}

.margin_bottom_25px{
    margin-bottom: 25px;
}


.bg-label-mali {
    background-color: #28b5b5 !important;
    color: #fff !important;
}
.bg_white{
    background-color: #fff;
}

.button_writememo_save_margin{
    margin-top: 40px;

}

.price_check_icon {
    display: inline-grid !important;
}

.content_video{
    margin-top:50px;
    margin-bottom: 50px;
}

.content_read{
    font-size: 17px;
}

.service_p{
    text-align: left;
    text-indent: 0px;
}

.image_gif_cover{
    width: 150px;
}

.image_gif_cover2{
    width: 300px;
}

.margin_bottom_25px_del{
    margin-top: -80px;
}

.border_no_bottom{
    border: 0px !important;
}

.service_icon_size {
    font-size: 60px;
    color: #28b4b3;
    margin-bottom: 20px;
}
.image_procedure_cover{
    width: 250px;
    padding-bottom: 40px;
}

.font-color-topic{

    color: #516377;
}

.topic_icon_size{
    width: 60px !important;
    height: 60px !important;
}

.dash{
    color:#8d99a6 !important;
    opacity: 0.6;
}
.padding-l-10{
    padding-left:10px;
}

.padding-r-10{
    padding-right:10px;
}

.margin-r-10{
    margin-right: 10px;
}
.margin-bottom-20{
    margin-bottom:20px;
}

.margin-b-t-20{
    margin-top:20px;
    margin-bottom:20px;
}

.margin-top-10-del{
    margin-top:-50px !important;
}

.border_bottom_none{
    border-bottom: 0px !important;
}

.padding-top-5{
    padding-top:5px;
}

.bg-gray_none_check{
    background-color: #bab8b8;
}
.react-joyride__tooltip button{
    color:#FFF!important;
}

.react-joyride__tooltip button[data-action="skip"]{
    color:#28b5b5!important;
    border: 1px solid  #28b5b5 !important;
    border-radius: 5px  !important;
}

.modal_title{
    font-size: 1em!important;
}

.modal-backdrop{
    background-color: #000000 !important;
}

.modal-backdrop.show{
    opacity: 0.8 !important;
}

.hide{
    display: none !important;
}

.checkbox_ui{
    width: 2em !important;
    height: 2em !important;
}

.box_input_title{
    height: 60px;
    font-size: 18px !important;
}
.wyswyg_container .form-control{
    font-size: 18px  !important;
}

.margin-top-20{
    margin-top:30px !important;
}
.receiver_memo_active{
    border: solid 2px #28b5b5 !important;
}
.bs-stepper.vertical .bs-stepper-header .line{
    min-height: 10px !important;
}
.topic_memories_guide{
    font-size:20px;
    text-transform: uppercase;
}

.font_i{
    font-style: italic;
}

.padding-top-20{
   padding-top:20px !important;
}
.margin-l-10{
    margin-left: 10px;
}

.w-px-60{
    width: 60px;
}

.box_dashboard_add_receiver{
    min-height: 170px !important;
}

.image_redeem_cover{
    width: 40%;
}

.font_topic_modal{
    font-size:19px;
}
.cursor_click{
    cursor: pointer;
}

.icon_btn_dashboard{
    margin-right: 5px;
    margin-top:-3px;
}
.text-indent-5{
    line-height: 1.5em;
}

.p_read{
    font-size: 18px;
}
.frame_about{
    width: 100%;
    min-height: 900px;
    border:none;
    padding-left: 20px;
    padding-right: 20px;
}

.padding-bottom-5{
    padding-bottom: 5px;
}

package_font_badge{
    font-size: 12em !important;
}
.text-trial{
    margin-top: 20px;
    font-size: 16px;
    color: #28b5b5;
}

.content_modal{

    text-align: left;
    text-indent: 2em;
    font-size: 1.2em;
}
.content_modal_icon{
    font-size: 5em;
    color: #28b5b5;
}
